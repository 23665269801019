import React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import TaskListItem from "../components/TaskListItem";
import plant from "../../static/img/plant.png";
import BreadCrumb from "../components/BreadCrumb";

export const WhatWeDoNotDoPageTemplate = ({ topsection, subtitle, list }) => {
  const links = [
    {label: 'Home', value: '/'}
  ];
  return (
    <div className="container">
    <BreadCrumb links={links} currentPage={'What we do not do'}/>
      <div className="wwdnd-page">
        <section className="content-section">
          <div className="content-title">
            What we <strong>do not do</strong>
          </div>
          <div className="content-subtitle">{subtitle}</div>
          <TaskListItem tasks={list}/>

        </section>
        <img className={'plant'} src={plant} alt="plant"/>
      </div>
    </div>
  );
};

const WhatWeDoNotDoPage = ({ data }) => {
  const { frontmatter } = data.markdown;
  return (
    <Layout>
      <WhatWeDoNotDoPageTemplate
        topsection={frontmatter.topsection}
        subtitle={frontmatter.subtitle}
        list={frontmatter.list}
      />
    </Layout>
  );
};

WhatWeDoNotDoPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default WhatWeDoNotDoPage;

export const pageQuery = graphql`
  query WhatWeDoNotDoPageTemplate {
    markdown: markdownRemark(
      frontmatter: { templateKey: { eq: "what-we-do-not-do-page" } }
    ) {
      frontmatter {
        topsection {
          bulbimage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        subtitle
        list {
          task
          id
        }
      }
    }
  }
`;
